
import FileResultCard from './FileResultCard.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'FileMetadata',
    components: {
        FileResultCard
    }
})
export default class FileMetadata extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly metadata: Record<string, string>;

    /* Data
    ============================================*/

    hasSections: boolean = false;
    get values(): FileMetadataItem[] {
        let self = this;
        if(!self.metadata) {};
        let data: FileMetadataItem[] = [];
        Object.keys(self.metadata).forEach(key => {
            let index = parseInt(key.split(':')[0]);
            if(!isNaN(index)) {
                let values = key.split(': ');
                let numValues = values.length;
                console.log(numValues);
                if(numValues == 3){
                    this.hasSections = true;
                    data[index - 1] = {
                        name: key.split(': ')[2],
                        value: self.metadata[key],
                        section: key.split(': ')[1]
                    };
                }
                else{
                    data[index - 1] = {
                        name: key.split(': ')[1],
                        value: self.metadata[key]
                    };
                }
            }
        });
        return data;
    }

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

type FileMetadataItem = {
    name: string;
    value: string;
    section?: string;
}

