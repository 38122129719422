
import FileExaminationReportOptions from '@/models/FileExaminationReportOptions';
import fileService from '@/services/ProjectFileService';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { AnyObject } from '@/types/generic.types';
import { appConfig } from '@/config/app.config';
import { appendQueryParams } from '@/utils/string.utils';
import { Component, Prop } from 'vue-property-decorator';
import { FileFlagStatus, IProjectFile, Permission, MediaType } from '@/types/resource.types';
import { getPropertyName } from '@/utils/object.utils';

@Component({
    name: 'FileOptions',
    components: {
        MxModal
    }
})
export default class FileOptions extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly file: IProjectFile;
    @Prop({type: Number, required: true})
    readonly mediaType: MediaType;

    /* Data
    ============================================*/

    items: any[] = [
        'Generate Report',
        'View Processing Timeline'
    ];
    reportOptionCheckboxes = reportOptionCheckboxes;
    showGenerateReportModal: boolean = false;
    selectedOptions: string[] = reportOptionCheckboxes.map(x => x.key);

    /* Computed
    ============================================*/
    get showVisualizationReport(): boolean{
        return true;
    }
    get canDownloadReports(): boolean {
        return this.$hasPermission(Permission.DownloadReports);
    }

    get canFlagFile(): boolean {
        if(this.file.signature2RefMatch) return false;
        return this.file.flagStatus === FileFlagStatus.NotFlagged;
    }

    get fileIsFlagged(): boolean {
        return this.file.flagStatus === FileFlagStatus.Flagged;
    }

    /* Methods
    ============================================*/

    buildOptions() {
        let options = new FileExaminationReportOptions() as AnyObject;
        Object.keys(options).forEach(key => {
            options[key] = !!this.selectedOptions.find(x => x === key);
        });
        return options as FileExaminationReportOptions;
    }

    async openReportModal() {
        this.selectedOptions = reportOptionCheckboxes.map(x => x.key);
        this.showGenerateReportModal = true;
    }

    async generateReport() {
        let { id } = this.file;
        let url = appConfig.BACKEND_URL + `/projectfile/${id}/report`;
        let options = this.buildOptions();
        url = appendQueryParams(url, options);
        //window.open(url);
        let fn = this.file.filename + '-report.pdf';
        //let result = await fileService.generateExaminationReportPdf(url, options);
        this.saveContent(url, fn);
        this.showGenerateReportModal = false;
    }
    saveContent(fileContents:string, fileName:string){
        const link = document.createElement('a');
        link.download = fileName;
        link.href = fileContents;
        link.click();
    }
    goToProject() {
        this.$router.push(this.$paths.PROJECT_SUMMARY(this.file.projectId));
    }

    async flagFile() {
        let file = { ...this.file, flagStatus: FileFlagStatus.Flagged };
        this.$emit('fileChange', file);

    }

    async unflagFile() {
        let file = { ...this.file, flagStatus: FileFlagStatus.NotFlagged };
        this.$emit('fileChange', file);
    }
    
    async sendRequest() {
        let confirmed = await this.$confirm(`Send advanced file analysis request for ${this.file.originalFilename}?`, {
            color: 'primary',
            title: 'Confirm Request',
            icon: 'mdi-information'
        });
        if(!confirmed) return;
        try {
            this.$loading = true;
            await fileService.requestAdvancedAnalysis(this.file.id);
            this.$toast.success('Advanced file analysis request sent.');
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Your request failed to send. Please contact support');
        } finally {
            this.$loading = false;
        }
    }

}

const options = new FileExaminationReportOptions();

const reportOptionCheckboxes = [{
    label: 'Include Verify Insights',
    key: getPropertyName(options, x => x.includeMedexInsights)
},{
    label: 'Include Project Information',
    key: getPropertyName(options, x => x.includeProjectInformation)
},{
    label: 'Include File Summary',
    key: getPropertyName(options, x => x.includeFileSummary)
},{
    label: 'Include Structural Analysis',
    key: getPropertyName(options, x => x.includeStructureAnalysis)
},{
    label: 'Include Attribute Similarity Analysis',
    key: getPropertyName(options, x => x.includeSimilarityAnalysisResult)
},{
    label: 'Include Modification Tests',
    key: getPropertyName(options, x => x.includeModificationTests)
},{
    label: 'Include Validation Tests',
    key: getPropertyName(options, x => x.includeValidationTests)
},{
    label: 'Include File Comments',
    key: getPropertyName(options, x => x.includeFileComments)
},{
    label: 'Include Decoded Metadata',
    key: getPropertyName(options, x => x.includeFileMetadata)
},{
    label: 'Include File Signature',
    key: getPropertyName(options, x => x.includeStructuralData)
},{
    label: 'Include Proprietary Data',
    key: getPropertyName(options, x => x.includeProprietaryData)
},{
    label: 'Include Unknown Data',
    key: getPropertyName(options, x => x.includeUnknownData)
},{
    label: 'Include C2PA Validation',
    key: getPropertyName(options, x => x.includeC2PA)
}];

