
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import pluralize from 'pluralize';
import ProjectStatusChip from '@/components/projects/ProjectStatusChip.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProject, IProjectSummary } from '@/types/resource.types';

@Component({
    name: 'ProjectFilesSummary',
    components: {
        MxInfoButton,
        ProjectStatusChip
    }
})
export default class ProjectFilesSummary extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object as () => IProjectSummary, required: true})
    readonly summary: IProjectSummary;

    /* Data
    ============================================*/

    /* Computed
    ============================================*/

    get fileSummaries(): FileSummaryCard[] {
        if(!this.summary) return [];
        let { filesSubmitted, filesSupported, filesWithKnownSignature } = this.summary;
        return [{
            count: filesSubmitted,
            label: pluralize('File', filesSubmitted) + ' Submitted'
        }, {
            count: filesSupported,
            label: pluralize('File', filesSupported) + ' Supported',
            info: 'Verify currently supports four file format families -  ISO Base Media File Format (MOV, MP4, M4V, 3GP, 3G2), RIFF Media Format (AVI), ASF Format (WMV), and JPEG File Interchange Format Family (JFIF).',
            infoTitle: 'About Files Supported'
        }, {
            count: filesWithKnownSignature,
            label: pluralize('File', filesWithKnownSignature) + ' With Known Signature',
            info: 'A submitted file\'s structure is analyzed against the Verify reference library for a signature match and is reported in the project file summary list below.  If a submitted file is not matched, it is flagged, and Verify will alert the user if a match does occur during a future library update.',
            infoTitle: 'About Known Signatures'
        }];
    }

    get project(): IProject {
        return this.summary?.project;
    }

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

export type FileSummaryCard = {
    count: number,
    label: string,
    info?: string,
    infoTitle?: string
}

